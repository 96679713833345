import { gql } from '@apollo/client'

export const CREATE_CERTICIFATE = gql`
  mutation createCert(
    $confirmationPIN: String!
    $issuerNUIS: String!
    $personalId: String!
    $phoneNumber: String!
    $contactEmail: String!
    $certificate: String!
    $certPassword: String!
  ) {
    registerCertificate(
      confirmationPIN: $confirmationPIN
      registerCertificate: {
        issuerNUIS: $issuerNUIS
        personalId: $personalId
        phoneNumber: $phoneNumber
        contactEmail: $contactEmail
        certificate: $certificate
        certPassword: $certPassword
      }
    ) {
      ARN
      personalId
    }
  }
`

export const UPDATE_CERTICIFATE = gql`
  mutation updateCertificate(
    $confirmationPIN: String!
    $issuerNUIS: String!
    $personalId: String!
    $phoneNumber: String!
    $contactEmail: String!
    $certificate: String!
    $certPassword: String!
  ) {
    updateCertificate(
      confirmationPIN: $confirmationPIN
      updateCertificate: {
        issuerNUIS: $issuerNUIS
        personalId: $personalId
        phoneNumber: $phoneNumber
        contactEmail: $contactEmail
        certificate: $certificate
        certPassword: $certPassword
      }
    ) {
      ARN
      personalId
    }
  }
`

export const GET_MERCHANT = gql`
  query getMerchant($id: String!) {
    getMerchant(id: $id) {
      certificate {
        personalId
        phoneNumber
        contactEmail
        CertData {
          notBefore
          notAfter
          subject {
            C
            L
            SN
            CN
            O
          }
          issuer {
            CN
          }
        }
      }
    }
  }
`

export const SEND_PIN = gql`
  mutation getPin($forNumber: String) {
    getPin(forNumber: $forNumber)
  }
`
