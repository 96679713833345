/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useCallback } from 'react'
import { SafeAreaView, View, Dimensions, TouchableOpacity, LayoutAnimation } from 'react-native'

import { useTheme } from 'react-native-themed-styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useFocusEffect } from '@react-navigation/native'
import { useLazyQuery, useMutation } from '@apollo/client'

import {
  Header,
  Icon,
  Text,
  ButtonGroup,
  Loader,
  SwipeList,
  ErrorModal,
} from '../../Components'

import { useConfig } from '../../Utils'

import Theme, { opacityConfig } from '../../Themes/Theme'

import themedStyles, {
  itemStyles,
  hiddenItemStyles,
} from './CashRegister.Styles'

import {
  LIST_CASH_REGISTERS,
  DELETE_CASH_REGISTER,
  UPDATE_CASH_REGISTER,
} from './CashRegister.Schema'

import images from '../../Themes/Images'
import { animations } from '../../Themes'

const unknown = 'UNKNOWN'
const active = 'ACTIVE'
const inactive = 'INACTIVE'

const { width } = Dimensions.get('screen')

function Item({
  requestUserEmail,
  createdAt,
  deviceName,
  deviceId,
  name,
  status,
  onPress,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  return (
    <View
      style={[
        styles.listItem,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity onPress={onPress} style={styles.touchableItem}>
        <View style={styles.growView}>
          {status === unknown ? (
            <>
              <View style={styles.itemFirstRow}>
                <Text i18nKey={requestUserEmail} />
                <Text
                  i18nKey={moment(createdAt).format('MMM DD, YYYY HH:mm A')}
                />
              </View>
              <Text
                i18nKey={deviceName}
                size="footnote"
                style={styles.smallText}
              />
              <Text
                i18nKey={deviceId}
                size="footnote"
                weight="light"
                style={styles.smallText}
              />
            </>
          ) : (
            <View style={styles.subContainer}>
              <View style={styles.growView}>
                <Text i18nKey={name} />
                <Text i18nKey={deviceId} size="footnote" />
              </View>
              <Icon
                source={images.chevronRight}
                color={colors.accent}
                size={20}
              />
            </View>
          )}
        </View>
      </TouchableOpacity>
    </View>
  )
}

Item.propTypes = {
  id: PropTypes.string,
  requestUserEmail: PropTypes.string,
  createdAt: PropTypes.string,
  deviceName: PropTypes.string,
  deviceId: PropTypes.string,
  name: PropTypes.string,
  status: PropTypes.string,
  selected: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

const hiddenProps = {
  UNKNOWN: {
    hiddenTitle: 'cash_register_deny_button',
  },
  ACTIVE: {
    hiddenTitle: 'users_deactivate_button',
  },
  INACTIVE: {
    hiddenTitle: 'users_delete_button',
  },
}

function HiddenItem({
  selected,
  needsConfirmation,
  onPress,
  onConfirm,
  onCancel,
  loading,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      {loading ? (
        <View style={styles.loader}>
          <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
        </View>
      ) : needsConfirmation ? (
        <TouchableOpacity style={styles.option} onPress={onPress}>
          <Text
            i18nKey={hiddenProps[selected].hiddenTitle}
            size="footnote"
            color={colors.white}
          />
        </TouchableOpacity>
      ) : (
        <View style={styles.optionFull}>
          <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
            <Icon source={images.deny} size={24} />
            <Text
              i18nKey="receipt_button_confirm"
              color={colors.white}
              style={styles.confirmText}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancel} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={18} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  selected: PropTypes.string,
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function CashRegister({ navigation: { goBack, navigate } }) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)

  const [limit] = useState(20)
  const [selected, setSelected] = useState(unknown)
  const [items, setItems] = useState([])
  const [loading1, setLoading] = useState(true)
  const [value, setValue] = useState('')
  const [opened, setOpened] = useState(false)
  const [cashId, setId] = useState('')

  const [collapsed, setCollapsed] = useState(true)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [rightOpenValueFull] = useState(-width + 30)
  const options = [
    {
      title: 'cash_register_pending_label',
      type: unknown,
    },
    {
      title: 'cash_register_active_label',
      type: active,
    },
    {
      title: 'cash_register_inactive_label',
      type: inactive,
    },
  ]

  const [listCashRegisters, { data, refetch, fetchMore }] = useLazyQuery(
    LIST_CASH_REGISTERS,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [updateDevice, { loading: updateLoading }] = useMutation(UPDATE_CASH_REGISTER)

  const [deleteDevice, { loading: deleteLoading }] = useMutation(DELETE_CASH_REGISTER)

  useEffect(() => {
    if (data) {
      setItems(data?.listCashRegisters?.cashRegisters)
      setLoading(false)
    }
  }, [data])

  const refetchData = async () => {
    if (refetch) {
      await refetch()
    }
  }

  useFocusEffect(
    useCallback(() => {
      refetchData()
    }, [])
  )

  useEffect(() => {
    setLoading(true)
    setItems([])
    if (value) {
      listCashRegisters({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          limit: limit,
          filter: {
            name: 'name',
            value: value,
          },
        },
      })
    } else {
      listCashRegisters({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          limit: limit,
          status: selected,
        },
      })
    }
  }, [value, selected])

  const openRow = (rowMap, id) => {
    setId(id)
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(rightOpenValueFull)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    if (selected === active) {
      updateDevice({
        variables: {
          id: cashId,
          status: 'INACTIVE',
        },
      })
        .then(() => {
          // displayToast('cash_register_deactivate_success')
          closeRow(rowMap, id)
          refetch()
        })
        .catch(() => {
          // displayToast('cash_register_deactivate_error', images.warning)
          closeRow(rowMap, id)
        })
    } else if (selected === inactive) {
      setOpened(true)
      closeRow(rowMap, id)
    } else {
      deleteDevice({
        variables: {
          cashRegisterId: id,
        },
      })
        .then(() => {
          // displayToast('cash_register_deny_success')
          closeRow(rowMap, id)
          refetch()
        })
        .catch(() => {
          // displayToast('cash_register_deny_error', images.warning)
          closeRow(rowMap, id)
        })
    }
  }

  const confirmDelete = () => {
    setOpened(false)
    deleteDevice({
      variables: {
        cashRegisterId: cashId,
      },
    })
      .then(() => {
        listCashRegisters({
          variables: {
            locId: locationId,
            cashRegisterId: deviceId,
            limit: limit,
            status: selected,
          },
        })
      })
      .catch(() => {
        setOpened(false)
      })
  }

  const renderItem = ({ item, index }) => (
    <Item
      {...item}
      onPress={() => {
        if (selected === unknown && !value) {
          navigate('NewRegister', {
            cashId: item.id,
          })
        } else {
          navigate('RegisterDetails', {
            cashId: item.id,
            refetch,
          })
        }
      }
      }
      isFirst={index === 0}
      isLast={index === items.length - 1}
    />
  )

  const renderHiddenItem = ({ item, index }, rowMap) => {
    return (
      <HiddenItem
        selected={item.status}
        needsConfirmation={needsConfirmation}
        onPress={() => openRow(rowMap, item.id)}
        onConfirm={() => deleteRow(rowMap, item.id)}
        onCancel={() => closeRow(rowMap, item.id)}
        loading={updateLoading || deleteLoading}
        isFirst={index === 0}
        isLast={index === items.length - 1}
      />
    )
  }

  const onEndReached = () => {
    if (!data?.listCashRegisters?.nextToken) {
      return
    }

    fetchMore({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        limit: limit,
        nextToken: data.listCashRegisters.nextToken,
        status: selected,
      },
    })
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title="header_cash_register"
          image={images.back}
          onPress={() => goBack()}
        />
        <TouchableOpacity
          onPress={() => {
            LayoutAnimation.configureNext(opacityConfig)
            setCollapsed(prev => !prev)
          }}
          style={styles.noteView}
        >
          <View style={styles.noteHeader}>
            <Icon source={images.info} size={20} />
            <Text
              i18nKey="cash_register_note"
              weight="bold"
              style={styles.noteTitle}
            />
          </View>
          <Text i18nKey="cash_register_note_description" size="footnote" style={collapsed ? styles.hiddenText : null} />
        </TouchableOpacity>

        <SwipeList
          data={items}
          renderItem={renderItem}
          renderHiddenItem={renderHiddenItem}
          searchValue={value}
          onSearch={setValue}
          disableRightSwipe
          recalculateHiddenLayout
          searchPlaceholder="cash_register_search_placeholder"
          rightOpenValue={-100}
          onRowClose={() => setNeedForConfirmation(true)}
          ListEmptyComponent={
            loading1 ? null : <Text i18nKey="cash_register_empty" />
          }
          ListHeaderComponent={
            !value && (
              <View style={styles.buttonGroup}>
                <ButtonGroup
                  selected={selected}
                  onPress={(type) => {
                    setSelected(type)
                    setLoading(true)
                  }}
                  options={options}
                />
              </View>
            )
          }
          ListFooterComponent={loading1 ? <Loader size={32} /> : null}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
          refreshing={false}
          onRefresh={() => refetch()}
          onEndReachedThreshold={0.5}
          onEndReached={() => onEndReached()}
        />
      </SafeAreaView>
      <ErrorModal
        isVisible={opened}
        icon={images.delete}
        title="cash_register_modal_title"
        description="cash_register_modal_description"
        primaryText="cash_register_yes_button"
        tertiaryText="printers_button_cancel"
        primaryAction={() => confirmDelete()}
        tertiaryAction={() => setOpened(false)}
        onBackdropPress={() => setOpened(false)}
        onBackButtonPress={() => setOpened(false)}
        onClosePress={() => setOpened(false)}
      />
    </>
  )
}

CashRegister.propTypes = {
  navigation: PropTypes.object,
}

export default CashRegister
